// GER
import { de } from "vuetify/lib/locale";

export const locale = {
  current: "de",
  common: {
    all: "all",
    yes: "yes",
    no: "no",
    programId: "Program ID",
    programName: "Program Name",
    startDateFuture: "Startdatum darf nicht in der Zukunft liegen",
    atLeastOneItem: "Muss mindestens einen Eintrag enthalten",
    startFrom: "Beginnt am",
    endsat: "Endet am",
    products: "Produkte",
    cities: "Städte",
    deepLink: "Deep Link"
  },
  $vuetify: {
    ...de
  },
  menu: {
    dashboard: "Dashboard",
    newPost: "Add new post",
    users: "Users",
    shops: "Shops",
    shopPosts: "Shop Posts",
    socialPosts: "Social Posts",
    banners: "Banners",
    messages: "Messages",
    broadcast: "Broadcast",
    shoppingEvents: "Shopping Events",
    donationGoals: "Donation Goals",
    shopAdvertisedPosts: "Shop Advertised Posts",
    orders: "Orders",
    categorise: "Categories",
    shopCategories: "Shop Categories",
    oobleeCategories: "Ooblee Categories",
    mainFeedCategories: "Main Feed Categories",
    mainPageSectors: "Main Page Sectors",
    recommendedCategories: "Recommended ",
    sponsoredBy: "Sponsored By",
    eventsAndNews: "Events & News",
    suggestedForYou: "Suggested for you",
    recommendedBy: "Recommended by",
    promotions: "Promotions",
    titleDefinitions: "Title Definitions",
    promotionInstances: "Promotion instances",
    bannerDefinitions: "Banner definitions",
    bannerInstances: "Banner instances",
    broadcastMessages: "Broadcast Messages",
    memberships: "Memberships",
    apUsers: "AP Users",
    scheduler: "Scheduler",
    influencers: "Influencers",
    allUsers: "All users",
    referralInvitation: "Referrals",
    coins: "Coins",
    campaigns: "Campaigns",
    welcomeScreen: "Welcome screen",
    advertising: "Advertising",
    giftCard: "Geschenkgutschein",
    amount: "Betrag",
    editGiftCardVoucher: "Geschenkgutschein bearbeiten",
    newGiftCardVoucher: "Neuer Geschenkgutschein",
    treatCampaign: "Treat campaign",
    generalSettings: "General settings",
    payments: "Payments"
  },
  mfCategories: {
    order: "Order",
    name: "Name of category",
    image: "Image",
    city: "City",
    active: "Active",
    type: "Type"
  },
  commonTable: {
    email: "email",
    date: "Date",
    userName: "User Name",
    user: "User",
    category: "Category",
    categories: "Categories",
    shoppingEventPosts: "Shopping Event Posts",
    total: "Total",
    published: "Published",
    city: "City",
    cities: "Cities",
    shops: "Shops",
    shopName: "Shop Name",
    shopId: "Shop ID",
    productName: "Product Name",
    productPicture: "Product Picture",
    productDescription: "Product Description",
    productPrice: "Product Price",
    productDiscount: "Product Discount",
    discountInPercentage: "Discount %",
    discountInCurrency: "Discount €",
    oobleeDiscountInCurrency: "Ooblee Discount €",
    status: "Status",
    product: "Product",
    price: "Price",
    orderType: "Order Type",
    paid: "Paid",
    finalPrice: "Final Price",
    priceBeforeDiscount: "Price Before Discount",
    oldPrice: "Old Price",
    adminStatus: "Status (Admin)",
    shopStatus: "Status (Shop)",
    quantity: "Quantity",
    shoppingEvent: "Shopping Event",
    shopProduct: "Shop Product",
    donationAmount: "Donation Amount",
    promoStart: "Promo Start",
    promoFinish: "Promo Finish",
    userRegStart: "Users registration start from",
    userRegFinish: "Users registration finish on",
    numOfRef: "Number of new referral users",
    voucherValue: "Voucher value",
    name: "Name",
    startFrom: "Beginnt am",
    endsat: "Endet am",
    finished: "Finished",
    validFrom: "Valid From",
    validUntil: "Valid Until",
    type: "Type",
    products: "Produkte"
  },
  pages: {
    shops: {
      entityName: "Name",
      email: "email",
      phoneNumber: "Phone Number",
      date: "Date",
      cityCountry: "City (Country)",
      activated: "Activated",
      groupShopping: "Group Shopping",
      suggestedShopping: "Suggested Shopping",
      redirectUrl: "Redirect URL",
      donations: "Donations",
      personalSpecialOffer: "Personal Special Offer",
      banned: "Banned",
      cityShopping: "City Shopping",
      subscriptions: "Subscriptions",
      gift: "Gift",
      shop: "Shop",
      tags: "tags",
      promotions: "promotions",
      edit: "Edit",
      language: "language",
      shops: "shops",
      activeShopsWithPostCount: "Active Shops with post count"
    },
    shopPosts: {
      shopName: "Shop Name",
      date: "Date",
      productPicture: "Product Picture",
      productName: "Product Name",
      gender: "Gender",
      productDescription: "Product Description",
      productDiscount: "Product Discount",
      productPrice: "Product Price",
      city: "City",
      category: "Category",
      published: "Published",
      editors: "Editors",
      product: "Product",
      categoryPicker: "Category Picker",
      tags: "Tags",
      delete: "Delete",
      edit: "Edit"
    },
    influencers: {
      influencer: "Influencer",
      gender: "Gender",
      requestedDate: "Requested Date",
      profile: "Profile",
      suspended: "Suspended",
      approveInfluencer: "Approve as Influencer",
      sendEmail: "Send email"
    }
  },
  lang: {
    me: "Montenegrin",
    en: "English",
    de: "German"
  }
};
